import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Container } from '../../components/Container';
import { ApiContext } from '../../contexts/ApiContext';
import FetchUserAndFirebaseDocsOnAuth from '../../components/fetchFirebaseDocsOnAuth.js'
import { Button } from '../../components/Button.js';
import UseCustomClaims from '../../components/UseCustomClaims.js';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';

const formatDateOutput = (date) => {
    return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    }).format(new Date(date));
};

const getAffiliateNameFromUrlString = (url) => {
    if (url.includes('/')) {
        const parts = url.split('/');
        return parts[parts.length - 1];
    }
    return url;
};

const AffiliateSelfSearch = () => { // tableData, setTableData,
    const claims = UseCustomClaims();
    const [tableData, setTableData] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [searchExecuted, setSearchExecuted] = useState(false);
    const [error, setError] = useState(null); // State for handling errors
    const {affiliateName, apiUrl} = useContext(ApiContext);
    const user = FetchUserAndFirebaseDocsOnAuth();
    const auth = getAuth();

    const [transactionsAffiliateHasBeenPaidFor, setTransactionsAffiliateHasBeenPaidFor] = useState([]);


    // 1: Get the users affiliate -> affiliate name and use this in place of the affiliate code

    const searchAnAffiliate = useCallback(() => {
        setSearchExecuted(true);
        setError(null); // Clear previous errors
        setTableData([]);

        if (startDate && !endDate) {
            // If start date set and end date not set, set end date to today
            // format must be yyyy-mm-dd
            setEndDate(new Date().toISOString().slice(0, 10));
        } else if (!startDate && endDate) {
            // If end date set and start date not set, set start date to 2021-01-01
            setStartDate('2024-01-01');
        }

        if (affiliateName === '') {
            setError("Affiliate name is required to fetch data.");
            return;
        }

        if ((startDate === '' || !startDate) && endDate === '') {
            fetch(`${apiUrl}/affiliateSearchSelf/${claims.claims.companyId}/${affiliateName}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`Error fetching data: ${response.status}`);
                    }
                    return response.json();
                })
                .then(data => {
                    setTableData(data.purchase);
                    setTransactionsAffiliateHasBeenPaidFor(data.transactionsAffiliateHasBeenPaidFor || []);
                })
                .catch(error => {
                    console.error("Error fetching data:", error);
                    setError("Failed to fetch data. If this error persists, please contact support.");
                });
        } else {
            // returnCustomerPurchasesBetweenDates -- this has a different endpoint!
            fetch(`${apiUrl}/affiliateSearchSelfWithDates/${claims.claims.companyId}/${affiliateName}/${startDate || '2021-01-01'}/${endDate || new Date().toISOString().slice(0, 10)}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`Error fetching data: ${response.status}`);
                    }
                    return response.json();
                })
                .then(data => {
                    setTableData(data.purchase);
                    setTransactionsAffiliateHasBeenPaidFor(data.transactionsAffiliateHasBeenPaidFor || []);
                })
                .catch(error => {
                    console.error("Error fetching data:", error);
                    setError("Failed to fetch data. If this error persists, please contact support.");
                });
        }
    }, [claims.claims, affiliateName, startDate, endDate, apiUrl]);


    useEffect(() => {
        searchAnAffiliate();
    }, [searchAnAffiliate]);

    // Checking if the user is an affiliate and if they are, checking if their company is paid up to date
    const [isAffiliate, setIsAffiliate] = useState(null);
    const { subscriptionStatus, setSubscriptionStatus } = useContext(ApiContext);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(()=>{
        if (auth.currentUser) {

            fetch(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/check-affiliate`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ affiliateEmail: auth?.currentUser?.email })
            }).then((res) => res.json()).then(data => {
                setLoading(true);

                fetch(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/getInitialPayment`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ email: data.stripePurchaserEmail })
                })
                .then((res) => res.json()).then(data => {
                    if (data?.status === 'Email not found') {
                        setSubscriptionStatus(false);
                        localStorage.setItem('subscriptionStatus', false);
                        setLoading(false);
                        navigate('/contact-company');
                    } else {
                        setLoading(false)
                    }
                })
                .catch(error => {
                    console.error("Error checking affiliate subscription status:", error);
                    setError("Failed to verify subscription status. Please try again later.");
                })
                .finally(() => {
                    setLoading(false);
                });
            })
        }
    }, [user?.email])


    return (
        <div>
            <Container>
                <div>
                    <div className="pb-8 mx-auto" style={{ minHeight: '65vh' }}>
                        <div className="pb-4">
                            <h1 className="font-display text-2xl tracking-tight text-slate-900 sm:text-3xl">
                                Sales and Earnings
                            </h1>
                            <p className="mt-2 mb-4 text-lg tracking-tight text-slate-700">
                                Find all sales you have made as an affiliate and your expected earnings!
                            </p>
                        </div>
                        {error && (
                            <div className="mb-4 p-4 text-red-700 bg-red-100 border border-red-400 rounded">
                                {error}
                            </div>
                        )}
                        <div className="flex flex-wrap items-center space-y-4 md:space-y-0 md:space-x-4">
                            <div className="flex-grow">
                                <label htmlFor="startDate" className="mb-3 block text-sm font-medium text-gray-700">
                                    Start Date:
                                </label>
                                <input
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                    className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                                />
                            </div>

                            <div className="flex-grow">
                                <label htmlFor="endDate" className="mb-3 block text-sm font-medium text-gray-700">
                                    End Date:
                                </label>
                                <input
                                    type="date"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                    className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                                />
                            </div>
                            <div>
                                <div className="mt-8">
                                    <Button onClick={searchAnAffiliate}>Search</Button>
                                </div>
                            </div>
                            
                        </div>
                        

                        {searchExecuted && (!tableData || tableData.length === 0) && (
                            <div>
                                <p className="pt-8">No earnings data available yet. Please check back later</p>
                            </div>
                        )}
                        <div>
                            {(tableData.length > 0 && tableData) && (
                                <div>
                                    <table className="dataTable">
                                        <thead>
                                            <tr>
                                                <th>Purchaser UUID</th>
                                                <th>Platform</th>
                                                <th>Sandbox</th>
                                                <th>Product Id</th>
                                                <th>Purchase Date</th>
                                                <th>Expiration Date</th>
                                                <th>Renewal Intent</th>
                                                <th>Amount USD</th>
                                                <th>Currency</th>
                                                <th>Status of Payment</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tableData.flatMap((row, rowIndex) => (
                                                Object.values(row.transactions).map((purchase, purchaseIndex) => (
                                                    <tr key={`${rowIndex}-${purchaseIndex}`}>
                                                        <td>{getAffiliateNameFromUrlString(row.applicationUsername)}</td>
                                                        <td>{purchase.platform}</td>
                                                        <td>{purchase.sandbox ? "Yes" : "No"}</td>
                                                        <td>{purchase.productId}</td>
                                                        <td>{formatDateOutput(purchase.purchaseDate)}</td>
                                                        <td>{purchase.expirationDate ? formatDateOutput(purchase.expirationDate) : "N/A"}</td>
                                                        <td>{purchase.renewalIntent}</td>
                                                        <td>{(purchase.amountUSD !== null && purchase.amountUSD !== undefined) ? purchase.amountUSD : "Not Available - Perhaps it's in sandbox"}</td>
                                                        <td>{(purchase.currency !== null && purchase.currency !== undefined) ? purchase.currency : "Not Available"}</td>
                                                        <td className={` ${transactionsAffiliateHasBeenPaidFor && transactionsAffiliateHasBeenPaidFor.includes(purchase.transactionId) ? 'text-green-600' : 'text-red-600'}`}>
                                                            {(transactionsAffiliateHasBeenPaidFor && transactionsAffiliateHasBeenPaidFor.includes(purchase.transactionId)) ? "Paid" : "Unpaid"}
                                                        </td>
                                                    </tr>
                                                ))
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default AffiliateSelfSearch;
